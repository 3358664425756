<template>


  <div v-if="!is_load">

    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
       
        <router-link to="/admin/tripassigment/lists"> Quản lý Tuyến xe</router-link>
      </li>

      <li class="breadcrumb-item">Thêm mới Tuyến xe</li>
    </ol>
     <FormValidate v-slot="{ invalid ,reset }">

    <form   @submit.prevent="onSubmit" @reset.prevent="reset" id="sendform">
      
      <div class="container">
        <ul class="nav nav-tabs" role="tablist"  style='display: none;'>
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#home"
              >Tiếng Việt</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#menu1">English</a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">

      

          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Thông tin chung </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                     


                         <div class="col-sm-10">
                       
                        <div class="form-group" v-if="business!=null">
                          <label for="name"
                            >Tên Doanh nghiệp  <span class="text-danger">*</span></label
                          >
                        

                           <InputValidate name="Tên Doanh Nghiệp" rules="required" v-slot="{ errors }">
                            
                              <select name="id_DN" v-model="Form.id_DN" class="form-control" >
                                 <option  value=""> Tuỳ chọn </option>
                                 <option v-for="(v,k) in business" :key="k" :value="k">{{v.name}}</option>
                              </select>
                             
                              <span class='text text-danger'>{{ errors[0] }}</span>
                            </InputValidate>
                                               
                            
                        </div>
                       
                      </div>



                        
                     


                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            > Biển số xe  </label
                          >                          
                              <input v-model="Form.bienSoXe" type="text" name="bienSoXe"  class="form-control" />

                        </div>
                      </div>

                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            > Ngày ra bến   </label
                          >                          
                              <input v-model="Form.ngayRaBen" type="text" name="ngayRaBen"  class="form-control" />

                        </div>
                      </div>
                        <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            > Tuyến đường  </label
                          >                          
                              <input v-model="Form.tuyenDuong" type="text" name="tuyenDuong"  class="form-control" />

                        </div>
                      </div>
                       <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            >  Loại xe  </label
                          >                          
                              <input v-model="Form.loaiXe" type="text" name="loaiXe"  class="form-control" />

                        </div>
                      </div>
                   

                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            >  Số Ghế  </label
                          >                          
                              <input v-model="Form.soGhe" type="text" name="soGhe"  class="form-control" />

                        </div>
                      </div>



                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            >  Quầy vé   </label
                          >                          
                              <input v-model="Form.quayVe" type="text" name="quayVe"  class="form-control" />

                        </div>
                      </div>


                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            >  Giá vé   </label
                          >                          
                              <input v-model="Form.giaVe" type="text" name="giaVe"  class="form-control" />

                        </div>
                      </div>

                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            >  Dừng bán vé   </label
                          >                          
                              <input v-model="Form.dungBanVe" type="text" name="dungBanVe"  class="form-control" />

                        </div>
                      </div>

                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            >  Khoảng cách  </label
                          >                          
                              <input v-model="Form.khoangCach" type="text" name="khoangCach"  class="form-control" />

                        </div>
                      </div>



                       




                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
            <button
              type="button"

              class="btn btn-sm btn-primary"
               @click="ViewAlert"
               v-if="invalid"
            >
              <i class="fad fa-save"></i> Save
            </button>


             <button
              type="submit"

              class="btn btn-sm btn-primary"
              
               v-else
            >
              <i class="fad fa-save"></i> Save
            </button>


             <button
              type="reset"
              class="btn btn-sm btn-warning ml-3"
              @click="myreset"
                 :disabled="invalid"
            >
              <i class="fad fa-save"></i> Reset
            </button>
            
             
    
           
          </div>
        </div>
      </div>
    </form>
  </FormValidate>
  </div>
  <div v-else>
        <MyLoadTheme>
             
                <MyLoading v-for="k in 10" :key="k"></MyLoading>
           
        </MyLoadTheme>
  </div>
</template>

<script>
import axios from "axios";

  
export default {
  data() {
    return {
      is_load: false,
      img_one: "",
     
     
      list_cate: null,
      isLogin: null,
      Form: {
          bienSoXe:'',
          ngayRaBen:"",
          tuyenDuong:"",
          loaiXe:"",
          soGhe:"",
          quayVe:"",
          giaVe:"",
          dungBanVe:"",
          tenDoanhNghiep:"",
          khoangCach:"",
          logo:"",
          address:"",
          phone:"",
          id_DN:""

      },
      business:null

     
     
    };
  },
  created() {
    let isLogin = this.$session.get("isLogin");
    this.isLogin = isLogin.permistions.split(",");
    
  },
  
  mounted() {
    this.is_load=true;
     axios
      .get(window.my_api + "api/business/get-businesss", {
        headers: window.my_header
      })
      .then((res) => {
        if (res.status == 200) {
          this.business=  res.data.data;
          this.is_load=false;


        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });
          

  },
  methods: {
    
    onPicturefile(e) {
      let _this = this;
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        _this.img_one = reader.result;
      };
      reader.readAsDataURL(file);
    },
    myreset(){
        
                 Object.keys(this.Form).forEach(v => {
                      this.Form[v]="";
                  });
    },
     ViewAlert(){
        alert("Vui lòng nhập dữ liệu đẩy đủ.");
    },
    
    onSubmit: function () {
        let myForm = document.getElementById("sendform");

       let formData = new FormData(myForm);
        
        let bb= this.Form.id_DN;
       
         formData.append("tenDoanhNghiep",this.business[bb].name);
          formData.append("logo",this.business[bb].picture);
           formData.append("address",this.business[bb].address);
            formData.append("phone",this.business[bb].phone);

       
        
        axios
          .post(window.my_api + "api/tripassigment/create-or-update-tripassigment", formData, {
            headers: window.my_header,
          })
          .then((res) => {

            if (res.status == 200) {
               if(res.data.successed){
                    this.$swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Thêm thành công.",
                        showConfirmButton: false,
                        timer: 2500,
                      });
                      
                      this.myreset();
               }else{
                      this.$swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Vui lòng kiểm tra lại dữ liệu.",
                        showConfirmButton: false,
                        timer: 2500,
                      });
                      
                     
               }
            

            } else {
              alert("Thêm không thành công, vui lòng liên hệ admin");
             
            }
             this.is_load = false;
          });
      
    },
  },
};
</script>